import React from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AppLayout from "./layouts/AppLayout";
import theme from "./ThemeFile";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "@mui/material";
import ScreenSizeRestrict from "./components/ScreenSizeRestrict";
import LoginLayout from "./layouts/LoginLayout";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prezerve</title>
      </Helmet>
      <ScreenSizeRestrict />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Switch>
            <Route path="/hcp" component={AppLayout} />
            <Route path="*" component={LoginLayout} />
          </Switch>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
