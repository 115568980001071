import React from "react";
import { Analytics } from "aws-amplify";
import axios from "axios";
import getNewTokens from "./RefreshTokens";
import countDownTimer from "../components/timeOutCheck";
import { useHistory } from "react-router-dom";
import { getName } from "../CommonFunctions";

export const AuthContext = React.createContext();
const TIMEOUT_IN_MS = 36_00_000; // 1 hours, use 120_000  (2mins) for dev

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState({});
  const refreshTokens = getNewTokens();
  const userTimer = countDownTimer();

  const history = useHistory();

  const clearData = React.useCallback(() => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUser({});
  }, [setUser, setIsAuthenticated]);

  const onAuthErrored = React.useCallback(() => {
    clearData();
    history.replace("/logout");
  }, [history, setIsAuthenticated, clearData]);

  const timeOutDone = React.useCallback(() => {
    history.push(`/timeout`);
    localStorage.clear();
  }, [history, clearData]);

  const logout = React.useCallback(async () => {
    let accessToken = localStorage.getItem("accessToken");
    clearData();
    await Promise.all[
      (axios({
        method: "delete",
        url: "/api/superAdminService/logout",
        headers: { "x-access-token": `${accessToken}` },
      }),
      Analytics.updateEndpoint({
        userAttributes: {
          uID: ["0"],
        },
      }))
    ];
    history.replace("/logout");
  }, [setIsAuthenticated, setUser, history]);

  const changeUser = React.useCallback(
    async (userObj) => {
      if (userObj?.userId) {
        setIsAuthenticated(true);
        setUser(userObj);
        localStorage.setItem("siteCode", userObj.siteCode);
        localStorage.setItem(
          "userName",
          getName(userObj.firstName, userObj.lastName)
        );
        localStorage.setItem("userId", userObj.userId);
        await Analytics.updateEndpoint({
          userAttributes: {
            uID: [userObj.userid],
          },
        });
      } else {
        logout();
      }
    },
    [setIsAuthenticated, setUser, logout]
  );

  React.useEffect(() => {
    userTimer(TIMEOUT_IN_MS, timeOutDone);
    return () => {
      // Anything in here is fired on component unmount.
      userTimer.stopTimer();
    };
  }, []);

  const makeApiCall = React.useCallback(
    async (method, url, data) => {
      let result = await axios({
        headers: { "x-access-token": localStorage.getItem("accessToken") },
        method,
        url,
        data,
      });
      if (result && result.status === 200) {
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }
        return result;
      } else {
        onAuthErrored();
      }
    },
    [isAuthenticated, onAuthErrored]
  );

  const makeAuthenticatedApiCall = React.useCallback(
    async (method, url, data) => {
      try {
        try {
          userTimer.setServerAccessed();
          return await makeApiCall(method, url, data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            await refreshTokens();
            return await makeApiCall(method, url, data);
          } else {
            return error.response;
          }
        }
      } catch (ex) {
        console.log("ERROR:", ex);
        onAuthErrored();
      }
      return { status: null, data: null };
    },
    [makeApiCall, onAuthErrored]
  );

  const value = React.useMemo(
    () => ({
      isAuthenticated,
      user,
      logout,
      changeUser,
      makeAuthenticatedApiCall,
    }),
    [isAuthenticated, user, logout, changeUser, makeAuthenticatedApiCall]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
