import React from "react";
import { LineChart, XAxis, YAxis, Line, CartesianGrid } from "recharts";
import { symptomBothersomeDisplayScale } from "../CommonFunctions";
import { Typography, Box } from "@mui/material";

const BothersomeGraph = ({ data }) => {
  const finalizedData = React.useMemo(
    () =>
      data.map((record) => ({ ...record, y: record.y.total / record.y.count })),
    [data]
  );
  // show dots on the datapoints in graph if there is only one actual datapoint
  const showDots = React.useMemo(() => {
    let count = 0;
    finalizedData.forEach((record) => {
      if (!isNaN(record.y)) {
        count++;
      }
    });
    return count === 1;
  }, [finalizedData]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "6px",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          letterSpacing: "0.17px",
          lineHeight: "24px",
          padding: "0 20px",
        }}
      >
        Bothersome
      </Typography>
      <LineChart
        width={390}
        height={250}
        data={finalizedData}
        margin={{ top: 20, right: 0, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          tickLine={false}
          axisLine={false}
          fontSize={12}
          fontFamily="Ubuntu, system-ui"
          fontWeight={400}
          letterSpacing={"0.4px"}
          interval={0}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis
          orientation="right"
          type="number"
          domain={[0, 4]}
          tickCount={6}
          includeHidden={true}
          tickFormatter={(value) => symptomBothersomeDisplayScale[value]}
          width={100}
          allowDecimals={false}
          tickLine={false}
          axisLine={false}
          interval={0}
          fontSize={12}
          fontFamily="Ubuntu, system-ui"
          fontWeight={400}
          letterSpacing={"0.4px"}
        />
        <Line
          type="linear"
          dataKey="y"
          dot={showDots}
          stroke="#20A297"
          strokeWidth={2}
          connectNulls={true}
        />
      </LineChart>
    </Box>
  );
};

export default BothersomeGraph;
