import React from "react";
import { Box, Typography, Tabs, Tab, Divider } from "@mui/material";
import PatientProfileIcon from "../../assets/icons/PatientProfileIcon";
import AlertIcon from "../../assets/icons/AlertIcon";
import MoreIcon from "../../assets/icons/MoreIcon";
import SymptomTab from "../../components/Tabs/SymptomTab";
import { PATIENT_TABS } from "../../CommonFunctions";
import MoreTab from "../../components/Tabs/MoreTab";
import NotificationTable from "../../components/NotificationTable";

function PatientViewTabs({
  tab,
  duration,
  changeTab,
  changeDuration,
  data,
  loading,
}) {
  const currentTabIndex = React.useMemo(() => {
    let tabIndex;
    for (const tabObj of Object.values(PATIENT_TABS)) {
      if (tabObj.val === tab) {
        tabIndex = tabObj.tabIndex;
      }
    }
    return tabIndex;
  }, [tab]);
  const handleChange = (_, newCurrentTab) => {
    changeTab(newCurrentTab);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="patient details tabs"
        >
          <Tab
            iconPosition="start"
            icon={
              <PatientProfileIcon
                fill={
                  currentTabIndex === PATIENT_TABS.SYMPTOM.tabIndex
                    ? "#00639C"
                    : "#181C218F"
                }
              />
            }
            label={
              <Typography
                sx={{
                  color:
                    currentTabIndex === PATIENT_TABS.SYMPTOM.tabIndex
                      ? "#00639C"
                      : "#181C218F",
                  fontWeight: 500,
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  lineHeight: "24px",
                  textTransform: "initial",
                }}
              >
                Symptoms
              </Typography>
            }
            sx={{
              minHeight: "inherit",
              gap: "8px",
              padding: "9px 16px",
            }}
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            icon={
              <AlertIcon
                fill={
                  currentTabIndex === PATIENT_TABS.ALERT.tabIndex
                    ? "#00639C"
                    : "#181C218F"
                }
              />
            }
            sx={{
              minHeight: "inherit",
              gap: "8px",
            }}
            label={
              <Typography
                sx={{
                  color:
                    currentTabIndex === PATIENT_TABS.ALERT.tabIndex
                      ? "#00639C"
                      : "#181C218F",
                  fontWeight: 500,
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  lineHeight: "24px",
                  textTransform: "initial",
                }}
              >
                Alerts
              </Typography>
            }
            {...a11yProps(1)}
          />
          <Tab
            iconPosition="start"
            icon={
              <MoreIcon
                fill={
                  currentTabIndex === PATIENT_TABS.MORE.tabIndex
                    ? "#00639C"
                    : "#181C218F"
                }
              />
            }
            sx={{
              minHeight: "inherit",
              gap: "8px",
            }}
            label={
              <Typography
                sx={{
                  color:
                    currentTabIndex === PATIENT_TABS.MORE.tabIndex
                      ? "#00639C"
                      : "#181C218F",
                  fontWeight: 500,
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  lineHeight: "24px",
                  textTransform: "initial",
                }}
              >
                More
              </Typography>
            }
            {...a11yProps(2)}
          />
        </Tabs>
        <Divider />
      </Box>
      <CustomTabPanel
        value={currentTabIndex}
        index={PATIENT_TABS.SYMPTOM.tabIndex}
      >
        <SymptomTab
          duration={duration}
          changeDuration={changeDuration}
          loading={loading}
          data={data}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={currentTabIndex}
        index={PATIENT_TABS.ALERT.tabIndex}
      >
        <NotificationTable />
      </CustomTabPanel>
      <CustomTabPanel
        value={currentTabIndex}
        index={PATIENT_TABS.MORE.tabIndex}
      >
        <MoreTab
          duration={duration}
          changeDuration={changeDuration}
          loading={loading}
          data={data}
        />
      </CustomTabPanel>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`patient-tabpanel-${index}`}
      aria-labelledby={`patient-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `patient-tab-${index}`,
    "aria-controls": `patient-tabpanel-${index}`,
  };
}

export default PatientViewTabs;
