import React from "react";

function CircleExclamationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill="#BA1A1A"
        d="M9 1a6.983 6.983 0 00-6.063 3.5c-1.28 2.188-1.28 4.844 0 7C4.188 13.688 6.47 15 9 15c2.5 0 4.781-1.313 6.031-3.5 1.281-2.156 1.281-4.813 0-7C13.781 2.344 11.5 1 9 1zm0 15c-2.875 0-5.5-1.5-6.938-4-1.437-2.469-1.437-5.5 0-8A8.018 8.018 0 019 0c2.844 0 5.469 1.531 6.906 4 1.438 2.5 1.438 5.531 0 8A7.94 7.94 0 019 16zM9 4c.25 0 .5.25.5.5v4c0 .281-.25.5-.5.5a.494.494 0 01-.5-.5v-4c0-.25.219-.5.5-.5zm.75 7a.74.74 0 01-.75.75.722.722 0 01-.75-.75.74.74 0 01.75-.75.76.76 0 01.75.75z"
      ></path>
    </svg>
  );
}

export default CircleExclamationIcon;
