import React from "react";

const SeenIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#00639C"
        d="M18.555 3.945a.662.662 0 010 .899L7.93 15.469a.662.662 0 01-.899 0L1.406 9.844a.662.662 0 010-.899.662.662 0 01.899 0L7.5 14.141 17.656 3.945a.662.662 0 01.899 0z"
      ></path>
    </svg>
  );
};

export default SeenIcons;
