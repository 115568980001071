import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import GridContainer from "../components/grid/GridContainer.jsx";
import GridItem from "../components/grid/GridItem.jsx";
import loginBackgroundImage from "../assets/images/loginbackground.png";
import LoginPage from "../views/public/LoginPage.jsx";
import OTP from "../views/public/OTP.jsx";
import TimeOutBackground from "../components/TimeOutBackground";
import ErrorPage from "../views/public/ErrorPage.jsx";

function LoginLayout() {
  return (
    <GridContainer
      style={{
        backgroundColor: "#fff",
        height: "100vh",
      }}
    >
      <GridItem
        lg={5}
        md={5}
        style={{
          backgroundImage: `url(${loginBackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></GridItem>
      <GridItem lg={7} md={7}>
        <Switch>
          <Route path={"/login"} component={LoginPage} />
          <Route path={"/timeout"} component={TimeOutBackground} />
          <Route path={"/otp"} component={OTP} />
          <Route path={"/errorpage"} component={ErrorPage} />
          <Redirect to={"/login"} />
        </Switch>
      </GridItem>
    </GridContainer>
  );
}
export default LoginLayout;
