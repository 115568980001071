import React from "react";
import { Box, Typography } from "@mui/material";

const NoDataComponent = (props) => {
  let styleData = props.data;
  return (
    <Box
      sx={{
        width: styleData.width,
        height: styleData.height,
        boxShadow:
          "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          textAlign: "center",
          paddingTop: styleData.paddingTop,
        }}
      >
        No Data Found...
      </Typography>
    </Box>
  );
};
export default NoDataComponent;
