import React from "react";
import {
  Modal,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import LeftIcon from "../assets/images/left.svg";

const SymptomImageViewer = ({
  activeSymptomForImage,
  setActiveSymptomForImage,
}) => {
  const [imageMoveOffsetX, setImageMoveOffsetX] = React.useState(-1);
  const [imageMoveOffsetY, setImageMoveOffsetY] = React.useState(-1);
  const [imageUrls, setImageUrls] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { makeAuthenticatedApiCall } = React.useContext(AuthContext);
  const fetchImageUrls = React.useCallback(
    async (fileNames) => {
      console.log("fetching image download urls");
      const response = await makeAuthenticatedApiCall(
        "POST",
        `/api/V1/providerService/getDetailsToDownloadImages`,
        { fileNames }
      );
      if (response.status === 200 && response.data) {
        setImageUrls(
          response.data?.map((imageObj) => Object.values(imageObj)?.[0]) ?? []
        );
      } else {
        console.error("[ERROR] image download url get", response);
      }
    },
    [setImageUrls, makeAuthenticatedApiCall]
  );

  React.useEffect(() => {
    if (activeSymptomForImage) {
      fetchImageUrls(activeSymptomForImage.fileNames);
    } else {
      // reset state
      setCurrentIndex(0);
      setImageUrls([]);
    }
  }, [activeSymptomForImage]);

  return (
    <Modal
      open={!!activeSymptomForImage}
      onClose={() => {
        setActiveSymptomForImage(null);
      }}
      keepMounted={false}
      slotProps={{
        backdrop: {
          sx: {
            background: "#181C21CC",
          },
        },
      }}
    >
      <Box>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            transform: "translateY(-50%)",
            left: "1%",
            borderRadius: "50%",
          }}
        >
          <IconButton
            sx={{
              background: "#181C21",
              boxShadow:
                "0px 3px 1px -2px #181C2233,0px 2px 2px 0px #181C2224, 0px 1px 5px 0px #181C221F",
              "&:disabled": {
                background: "#51606F",
              },
            }}
            disabled={currentIndex === 0}
            onClick={() => setCurrentIndex((idx) => idx - 1)}
          >
            <img src={LeftIcon} alt="previous image" />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translateX(-50%)",
            height: "600px",
            width: "400px",
            background: "#4F5359",
            borderRadius: "24px",
            boxShadow:
              "0px 11px 15px -7px #181C2233, 0px 24px 38px 3px #181C2224, 0px 9px 46px 8px #181C221F",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              position: "relative",
              borderRadius: "inherit",
            }}
          >
            {imageUrls.length > 0 ? (
              <Box
                onMouseMove={(e) => {
                  const r = e.target.getBoundingClientRect();
                  const xOffset = Math.floor(
                    ((e.clientX - r.left) / r.width) * 100
                  );
                  const yOffset = Math.floor(
                    ((e.clientY - r.top) / r.height) * 100
                  );
                  setImageMoveOffsetX(xOffset);
                  setImageMoveOffsetY(yOffset);
                }}
                onMouseLeave={() => {
                  setImageMoveOffsetX(-1);
                  setImageMoveOffsetY(-1);
                }}
                sx={{
                  borderRadius: "inherit",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  background: `url(${imageUrls[currentIndex]}), #4F5359`,
                  backgroundPosition: `${imageMoveOffsetX >= 0 ? imageMoveOffsetX + "%" : "50%"} ${imageMoveOffsetY >= 0 ? imageMoveOffsetY + "%" : "50%"}`,
                  transition: "background-position 0.2s ease-out",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                />
              </Box>
            )}
            {activeSymptomForImage && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-60px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "#FFFFFF",
                  background: "#181C21",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  display: "flex",
                  gap: "9px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    letterSpacing: "0.17px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {activeSymptomForImage?.date?.format("MMM D, YYYY") ?? ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    letterSpacing: "0.17px",
                  }}
                >
                  |
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    letterSpacing: "0.17px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {activeSymptomForImage.bothersome} Bothersome
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            transform: "translateY(-50%)",
            right: "1%",
            borderRadius: "50%",
          }}
        >
          <IconButton
            sx={{
              background: "#181C21",
              "&:disabled": {
                background: "#51606F",
                boxShadow:
                  "0px 3px 1px -2px #181C2233,0px 2px 2px 0px #181C2224, 0px 1px 5px 0px #181C221F",
              },
            }}
            disabled={currentIndex === imageUrls.length - 1}
            onClick={() => setCurrentIndex((idx) => idx + 1)}
          >
            <img
              src={LeftIcon}
              style={{ transform: "scaleX(-1)" }}
              alt="next image"
            />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default SymptomImageViewer;
