import React from "react";
import _ from "lodash";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import {
  PATIENT_DURATIONS,
  symptomBothersomeDisplayScale,
  calculateInterval,
} from "../../CommonFunctions";
import dayjs from "../../day";
import SymptomSection from "../SymptomSection";
import CalendarIcon from "../../assets/images/calendar.svg";

const SymptomTab = ({ duration, changeDuration, data, loading }) => {
  const symptoms = useSymptoms(duration, data);
  const [durationOpen, setDurationOpen] = React.useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        alignItems: "flex-start",
        padding: "32px 0",
      }}
    >
      <FormControl sx={{ width: "220px", position: "relative" }}>
        <InputLabel id="duration-label">Date Range</InputLabel>
        <Select
          labelId="duration-label"
          id="duration"
          value={duration}
          label="Date Range"
          sx={{
            paddingLeft: "36px",
          }}
          open={durationOpen}
          onOpen={() => setDurationOpen(true)}
          onClose={() => setDurationOpen(false)}
          onChange={(e) => {
            changeDuration(e.target.value);
          }}
        >
          <MenuItem value={PATIENT_DURATIONS.ONE_MONTH.val}>1 Month</MenuItem>
          <MenuItem value={PATIENT_DURATIONS.THREE_MONTHS.val}>
            3 Months
          </MenuItem>
          <MenuItem value={PATIENT_DURATIONS.SIX_MONTHS.val}>6 Months</MenuItem>
          <MenuItem value={PATIENT_DURATIONS.ALL.val}>All</MenuItem>
        </Select>
        <Box
          component={"label"}
          name="duration"
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            height: "24px",
            width: "24px",
            cursor: "pointer",
          }}
          onClick={() => {
            setDurationOpen(!durationOpen);
          }}
        >
          <img
            src={CalendarIcon}
            style={{ pointerEvents: "none" }}
            alt="calendar icon"
          />
        </Box>
      </FormControl>
      {loading ? (
        <Box
          sx={{
            height: "30vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              height: "80px",
            }}
          />
        </Box>
      ) : (
        <SymptomSection symptoms={symptoms} />
      )}
    </Box>
  );
};

function useSymptoms(duration, data) {
  const symptoms = React.useMemo(() => {
    const symptomData = data?.symptoms ?? [];
    // 1. group symptoms based on parentName and symptomName
    const groupedSymptom = _.groupBy(
      symptomData,
      (symptom) => (symptom.parentName ?? "") + symptom.symptomName
    );

    const result = Object.entries(groupedSymptom).map(
      ([, symptomsInAGroup]) => {
        // 2. sort based to symptoms in a group based on symptomDate
        symptomsInAGroup.sort(
          (a, b) => new Date(b.symptomDate) - new Date(a.symptomDate)
        );
        const symptomList = symptomsInAGroup.map((symptom) => {
          return {
            id: symptom.id,
            date: dayjs(symptom.symptomDate),
            fileNames: symptom.fileNames ?? [],
            bothersome: symptomBothersomeDisplayScale[symptom.severity - 1],
          };
        });

        // create intervals for the duration
        const { intervals } = calculateInterval(duration);
        // initialize graphs data
        const frequencyData = intervals.map((interval) => ({
          x: `${interval.end.format("M")}/${interval.end.format("D")}`,
          y: 0,
        }));
        const bothersomeData = intervals.map((interval) => ({
          x: `${interval.end.format("M")}/${interval.end.format("D")}`,
          y: { total: 0, count: 0 },
        }));

        // 3. fill graphs data
        for (const symptom of symptomsInAGroup) {
          const date = dayjs(symptom.symptomDate);
          const severity = symptom.severity;
          const matchingIntervalIndex = intervals.findIndex((interval) => {
            return date.isBetween(interval.start, interval.end, "day", "[]");
          });
          if (frequencyData[matchingIntervalIndex]) {
            // set frequency graph values
            frequencyData[matchingIntervalIndex].y += 1;
            // set bothersome graph values
            bothersomeData[matchingIntervalIndex].y.count += 1;
            bothersomeData[matchingIntervalIndex].y.total += severity - 1;
          } else {
            // set frequency graph values for the last interval
            frequencyData[intervals.length - 1].y += 1;
            // set bothersome graph values for the last interval
            bothersomeData[intervals.length - 1].y.count += 1;
            bothersomeData[intervals.length - 1].y.total += severity - 1;
          }
        }
        const hasParent = !!symptomsInAGroup[0].parentName;
        const groupObj = {
          heading: hasParent
            ? symptomsInAGroup[0].parentName
            : symptomsInAGroup[0].symptomName,
          subHeading: hasParent ? symptomsInAGroup[0].symptomName : null,
          symptomList,
          frequencyData,
          bothersomeData,
        };
        return groupObj;
      }
    );
    // sort by latest symptom date, if same then by no of records
    result.sort((a, b) => {
      if (a.symptomList[0].date.isSame(b.symptomList[0].date, "day")) {
        return b.symptomList.length - a.symptomList.length;
      } else if (a.symptomList[0].date.isAfter(b.symptomList[0].date, "day")) {
        return -1;
      } else {
        return 1;
      }
    });
    return result;
  }, [data, duration]);
  return symptoms;
}

export default SymptomTab;
