import React, { useState, useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import MoreTable from "../../components/MoreTable";
import DailyLifeImpactTable from "../../components/DailyLifeImpactTable";
import NoDataComponent from "../../components/NoDataComponent";

function More(props) {
  const [moreData, setMoreData] = useState(false);

  const fetchDailyLifeImpactSection = async () => {
    let moreTabData = props.data;
    if (moreTabData && moreTabData.symptomNotes.length > 0) {
      moreTabData.symptomNotes.sort(function (a, b) {
        return new Date(b.observationDate) - new Date(a.observationDate);
      });
      let moreArray = [];
      moreTabData.symptomNotes.map((item, index) => {
        let relatedSymptoms = [];
        moreTabData.symptoms.map((symptom) => {
          if (item.observationDate === symptom.symptomDate) {
            relatedSymptoms.push(symptom.symptomName);
          }
        });
        moreArray.push({
          id: index,
          relatedSymptoms: relatedSymptoms,
          observationDate: item.observationDate,
          observationText: item.observationText,
        });
      });
      setMoreData(moreArray);
    } else {
      setMoreData(false);
    }
  };
  useEffect(() => {
    fetchDailyLifeImpactSection();
  }, []);
  return props.loading ? (
    <Box
      sx={{
        height: "30vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        sx={{
          height: "80px",
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "32px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                letterSpacing: "0.25px",
                lineHeight: "24.7px",
                color: "#171C22",
              }}
            >
              Notes
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "900px",
              width: "900px",
              marginRight: "20px",
            }}
          >
            {moreData ? (
              <MoreTable moreData={moreData} />
            ) : (
              <NoDataComponent
                data={{ width: "888px", height: "300px", paddingTop: "15%" }}
              />
            )}
          </Box>
        </Box>
        <Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                letterSpacing: "0.25px",
                lineHeight: "24.7px",
                color: "#171C22",
              }}
            >
              Daily Life Impact
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "470px",
              width: "470px",
            }}
          >
            {props.data && props.data.dailyLifeImpactList.length > 0 ? (
              <DailyLifeImpactTable
                dailyLifeImpactList={props.data.dailyLifeImpactList}
              />
            ) : (
              <NoDataComponent
                data={{ width: "470px", height: "300px", paddingTop: "31%" }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default More;
