import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AuthContext } from "../context/AuthContext";
import MuiDataGrid from "./TableDriver";
import { getName } from "../CommonFunctions";
import PatientProfileIcon from "../assets/icons/PatientProfileIcon";
import NotificationIcon from "../assets/icons/NotificationIcon";
import NoDataComponent from "./NoDataComponent";

// table column defenition
const commonColumnOptions = {
  resizable: false,
  aggregable: false,
  gropable: false,
  hideable: false,
  pinnable: false,
};

const pageSizeOptions = [10, 20, 30];

const PatientTable = () => {
  const history = useHistory();
  const [paginationModel, setPaginationModel] = React.useState(() => {
    const url = new URLSearchParams(window.location.search);
    let page = url.get("page") || 0;
    page = parseInt(page);
    if (isNaN(page) || page < 0) {
      page = 0;
    }
    let pageSize = url.get("size") || pageSizeOptions[0];
    pageSize = parseInt(pageSize);
    if (isNaN(pageSize) || pageSizeOptions.indexOf(pageSize) === -1) {
      pageSize = pageSizeOptions[0];
    }
    return {
      pageSize,
      page,
    };
  });

  const handlePaginationModelChange = React.useCallback(
    (paginationModel) => {
      setPaginationModel(paginationModel);
      const searchParams = new URLSearchParams();
      if (paginationModel.page !== 0) {
        searchParams.set("page", paginationModel.page);
      }
      if (paginationModel.pageSize !== pageSizeOptions[0]) {
        searchParams.set("size", paginationModel.pageSize);
      }
      history.push(`/hcp?${searchParams.toString()}`);
    },
    [history]
  );
  const [patients, setPatients] = React.useState([]);
  const { makeAuthenticatedApiCall } = React.useContext(AuthContext);

  const handleNotificationClick = React.useCallback(
    (event, patientId) => {
      event.stopPropagation();
      history.push(`/hcp/patient/${patientId}/alert/all`);
    },
    [history]
  );

  const handleRowClick = React.useCallback(
    ({ row }) => {
      history.push(`/hcp/patient/${row.id}/symptom/3m`);
    },
    [history]
  );
  const columns = [
    {
      ...commonColumnOptions,
      field: "name",
      flex: 1,
      renderHeader: () => <ColumnHeader title="Patient" />,
      renderCell: (params) => <RowCell value={params.value} />,
    },
    {
      ...commonColumnOptions,
      field: "dob",
      flex: 1,
      renderHeader: () => <ColumnHeader title="Date of Birth" />,
      renderCell: (params) => (
        <RowCell value={dayjs(params.value).format("M/D/YYYY")} />
      ),
    },
    {
      ...commonColumnOptions,
      field: "lastLogin",
      flex: 1,
      renderHeader: () => <ColumnHeader title="Last app use" />,
      renderCell: (params) => (
        <RowCell value={dayjs(params.value).format("M/D/YYYY")} />
      ),
    },
    {
      ...commonColumnOptions,
      sortable: false,
      field: "patientId",
      flex: 0.3,
      renderHeader: () => <ColumnHeader title="" />,
      renderCell: (params) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {params.row.notificationCount && (
            <Button sx={{ minWidth: "initial" }}>
              <NotificationIcon
                handleNotificationClick={handleNotificationClick}
                data={params.row}
              />
            </Button>
          )}
          <Button sx={{ minWidth: "initial" }}>
            <PatientProfileIcon />
          </Button>
        </Box>
      ),
    },
  ];

  const fetchPatients = React.useCallback(async () => {
    try {
      const response = await makeAuthenticatedApiCall(
        "GET",
        "/api/V1/providerService/getpatientlist"
      );
      if (response.status !== 200) {
        console.log("error fetching patients");
        return;
      }
      const patients =
        response?.data?.map((patient) => ({
          id: patient.patientId,
          name: getName(patient?.firstName, patient?.lastName),
          dob: patient.dob,
          lastLogin: patient.lastLogin,
          notificationCount: patient.unread === 0 ? null : patient.unread,
        })) ?? [];
      setPatients(patients);
    } catch (err) {
      console.error("getpatientlist error");
    }
  }, [setPatients, makeAuthenticatedApiCall]);

  React.useEffect(() => {
    fetchPatients();
  }, []);

  return (
    <>
      {patients && patients.length > 0 ? (
        <MuiDataGrid
          rows={patients}
          columns={columns}
          onRowClick={handleRowClick}
          getRowHeight={() => "auto"}
          hidePagination={false}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          sx={{
            background: "white",
            padding: "8px",
            borderRadius: "16px",
            "&, [class^=MuiDataGrid]": { border: "none" },
            "div.MuiDataGrid-topContainer": {
              "&::after": {
                display: "none",
              },
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#181C210A",
            },
            boxShadow:
              "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
          }}
          pageSizeOptions={pageSizeOptions}
        />
      ) : (
        <NoDataComponent
          data={{ width: "1390px", height: "300px", paddingTop: "10%" }}
        />
      )}
    </>
  );
};

function ColumnHeader({ title }) {
  return (
    <Typography
      sx={{
        display: "inline",
        lineHeight: "24px",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  );
}

function RowCell({ value }) {
  return (
    <Box sx={{ display: "inline-block", padding: "16px 6px" }}>{value}</Box>
  );
}

export default PatientTable;
