import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Box } from "@mui/material";

import PatientDash from "../views/private/PatientDash";
import PatientView from "../views/private/PatientView";
import AboutPage from "../views/private/About";
import Navbar from "../components/Navbar";

function AppLayout() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "100%",
        background: "#FFF",
      }}
    >
      <Navbar />
      <Box
        sx={{
          overflowX: "auto",
          width: "100%",
          margin: "80px auto 0 auto",
          // min height should be vh - (navbar height + footer height)
          minHeight: "calc(100vh - 80px)",
          padding: "40px 24px",
        }}
      >
        <Switch>
          <Route path="/hcp/about" component={AboutPage} />
          <Route
            path="/hcp/patient/:patientId/:tab/:duration"
            component={PatientView}
          />
          <Route path="/hcp" component={PatientDash} />
          <Redirect to={"/login"} />
        </Switch>
      </Box>
    </Box>
  );
}

export default AppLayout;
