import { Typography, Box } from "@mui/material";
import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar } from "recharts";

const FrequencyGraph = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "6px",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "500",
          letterSpacing: "0.17px",
          lineHeight: "24px",
          padding: "0 20px",
        }}
      >
        Frequency
      </Typography>
      <BarChart
        width={360}
        height={250}
        data={data}
        margin={{ top: 20, right: -18, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" color="#A8ABB2" />
        <XAxis
          dataKey="x"
          tickLine={false}
          axisLine={false}
          fontSize={12}
          fontFamily="Ubuntu, system-ui"
          fontWeight={400}
          letterSpacing={"0.4px"}
        />
        <YAxis
          orientation="right"
          type="number"
          allowDecimals={false}
          tickLine={false}
          axisLine={false}
          interval={0}
          fontSize={12}
          fontFamily="Ubuntu, system-ui"
          fontWeight={400}
          letterSpacing={"0.4px"}
        />
        <Bar dataKey="y" fill="#7300E9" barSize={20} />
      </BarChart>
    </Box>
  );
};

export default FrequencyGraph;
