import React from "react";
import { Box, Button, Typography } from "@mui/material";
import FrequencyGraph from "../components/FrequencyGraph";
import BothersomeGraph from "../components/BothersomeGraph";
import MuiDataGrid from "./TableDriver";
import CameraIcon from "../assets/images/camera.svg";
import SymptomImageViewer from "../components/SymptomImageViewer";
import NoDataComponent from "./NoDataComponent";

const commonColumnOptions = {
  resizable: false,
  aggregable: false,
  gropable: false,
  hideable: false,
  pinnable: false,
  sortable: false,
};

const SymptomSection = ({ symptoms }) => {
  const [activeSymptomForImage, setActiveSymptomForImage] =
    React.useState(null);

  const columns = [
    {
      ...commonColumnOptions,
      field: "date",
      flex: 0.9,
      renderHeader: () => <ColumnHeader title="Date" />,
      renderCell: (params) => (
        <RowCell value={params.value?.format("MMM D, YYYY") ?? ""} />
      ),
    },
    {
      ...commonColumnOptions,
      field: "bothersome",
      flex: 1.1,
      renderHeader: () => <ColumnHeader title="How Bothersome" />,
      renderCell: (params) => <RowCell value={params.value} />,
    },
    {
      ...commonColumnOptions,
      field: "fileNames",
      flex: 0.7,
      renderHeader: () => <ColumnHeader title="" />,
      renderCell: (params) => (
        <RowCell
          value={
            <ImageButton
              row={params.row}
              setActiveSymptomForImage={setActiveSymptomForImage}
            />
          }
        />
      ),
    },
  ];

  return symptoms.length > 0 ? (
    <>
      <SymptomImageViewer
        activeSymptomForImage={activeSymptomForImage}
        setActiveSymptomForImage={setActiveSymptomForImage}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        {symptoms.map((symptom) => (
          <Box
            key={`${symptom.heading}_${symptom.subHeading}`}
            sx={{
              justifyContent: "stretch",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "28.01px",
                  fontWeight: "500",
                  color: "#171C22",
                }}
              >
                {symptom.heading}
              </Typography>
              {symptom.subHeading && (
                <Typography
                  sx={{
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#171C22B2",
                  }}
                >
                  {symptom.subHeading}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  width: "360px",
                  height: "380px",
                  boxShadow:
                    "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
                }}
              >
                <MuiDataGrid
                  rows={symptom.symptomList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 8, page: 0 },
                    },
                  }}
                  rowHeight={40}
                  sx={{
                    width: "360px",
                    height: "380px",
                    background: "white",
                    padding: "8px",
                    borderRadius: "16px",
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    "div.MuiDataGrid-topContainer": {
                      "&::after": {
                        display: "none",
                      },
                    },
                    "& .MuiDataGrid-columnSeparator": {
                      display: "none",
                    },
                    "& .MuiDataGrid-filler > *": {
                      border: "none",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "inherit",
                    },
                    "& .MuiDataGrid-cell": {
                      pointerEvents: "none",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      pointerEvents: "none",
                    },
                  }}
                  hidePagination={false}
                  pageSizeOptions={[8]}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  gap: "12px",
                  boxShadow:
                    "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
                  padding: "16px",
                }}
              >
                <FrequencyGraph data={symptom.frequencyData} />
                <BothersomeGraph data={symptom.bothersomeData} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  ) : (
    <NoDataComponent
      data={{ width: "1100px", height: "350px", paddingTop: "15%" }}
    />
  );
};

function ColumnHeader({ title }) {
  return (
    <Typography
      sx={{
        display: "inline",
        lineHeight: "24px",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  );
}

function RowCell({ value }) {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        lineHeight: "20.02px",
        letterSpacing: "0.17px",
        color: "#171C22",
        padding: "6px 0",
      }}
    >
      {value}
    </Typography>
  );
}

function ImageButton({ row, setActiveSymptomForImage }) {
  const handleImageButtonClick = React.useCallback(() => {
    setActiveSymptomForImage(row);
  }, [row, setActiveSymptomForImage]);

  return (
    row.fileNames?.length > 0 && (
      <Button
        size={"small"}
        startIcon={<img src={CameraIcon} alt="camera icon" />}
        sx={{
          background: "#181C2114",
          pointerEvents: "auto",
          borderRadius: "100px",
          padding: "2px 2px",
          width: "60px",
          minWidth: "auto",
          fontSize: "13px",
          lineHeight: "18px",
          letterSpacing: "0.16px",
          color: "#171C22",
        }}
        onClick={handleImageButtonClick}
      >
        {row.fileNames?.length}
      </Button>
    )
  );
}

export default SymptomSection;
