import React, { useEffect, useState, useRef, useContext } from "react";
import {
  useTheme,
  Card,
  Typography,
  Box,
  Stack,
  Link,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Helmet } from "react-helmet";
import { Formik, Field, Form } from "formik";
import { number, object } from "yup";
import Button from "../../components/customcomponents/CustomButton";
import { AuthContext } from "../../context/AuthContext";
import { LoginPageStyle } from "../../components/customstyles/LoginPageStyle";

const useStyles = () => {
  const theme = useTheme();
  return LoginPageStyle(theme);
};

const OTP_COUNTER_TIMEOUT_IN_SECONDS = 60;
function OTP() {
  const styles = useStyles();
  const history = useHistory();
  const resendOTPCouterIntervalRef = useRef();
  const { changeUser } = useContext(AuthContext);
  const [isLoginCompleted, setIsLoginCompleted] = useState(false);
  const [resendOTPCounter, setResendOTPCounter] = useState(
    OTP_COUNTER_TIMEOUT_IN_SECONDS
  );
  const yupSchema = object({
    otp: number()
      .typeError("Verification code must be a number.")
      .required("This Field is required."),
  });
  useEffect(() => {
    restartResendOTPCounter();
  }, []);
  const handleSubmit = async (values, formHelper) => {
    setIsLoginCompleted(true);
    let offset = new Date().getTimezoneOffset() * -1;
    try {
      let response = await axios.post("/api/providerAuthService/HCPLogin", {
        otp: values.otp,
        offset: offset,
        token: localStorage.getItem("otpToken"),
      });
      if (response.status === 200) {
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("accessToken", response.data.accessToken);
        changeUser({
          isAuthenticated: true,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          userId: response.data.userId,
          siteCode: response.data.siteCode,
        });
        history.push("./hcp");
      }
    } catch (err) {
      if (err.response?.status === 400) {
        formHelper.setFieldError("otp", "Enter a valid OTP.");
      } else {
        formHelper.setFieldError("otp", err.response.data.warningmessage);
      }
    }
    setIsLoginCompleted(false);
  };

  const restartResendOTPCounter = () => {
    if (resendOTPCouterIntervalRef.current) {
      clearInterval(resendOTPCouterIntervalRef.current);
    }
    setResendOTPCounter(OTP_COUNTER_TIMEOUT_IN_SECONDS);
    resendOTPCouterIntervalRef.current = setInterval(() => {
      setResendOTPCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(resendOTPCouterIntervalRef.current);
          resendOTPCouterIntervalRef.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };
  const generateDottedEmail = () => {
    let emailId = localStorage.getItem("emailId").trim();
    let numberOfStartChar = 2;
    let lengthOfEmail = emailId.length;
    let lastCharAfterAt = lengthOfEmail - emailId.indexOf("@");
    return (
      emailId &&
      emailId.slice(0, numberOfStartChar) +
        "*".repeat(lengthOfEmail - (numberOfStartChar + lastCharAfterAt)) +
        emailId.slice(-lastCharAfterAt, lengthOfEmail)
    );
  };
  const getOtpFromAPI = async () => {
    setIsLoginCompleted(true);
    try {
      const response = await axios({
        method: "post",
        url: "/api/providerAuthService/sendOtp",
        data: { email: localStorage.getItem("emailId") },
      });
      if (response.status === 200 && response.data.token) {
        restartResendOTPCounter();
        localStorage.setItem("otpToken", response.data.token);
        restartResendOTPCounter();
      }
    } catch (err) {
      console.log(err.response.data.statusDescription);
    }
    setIsLoginCompleted(false);
  };
  const handletermsOfUse = () => {
    window.open("/api/V1/resourceService/termsAndCondition/3", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>otp</title>
      </Helmet>
      <Stack
        sx={{
          height: "100vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={styles.formLaylout}>
          <Typography sx={styles.cardTitle}>Verify your identity</Typography>
          <Typography sx={styles.cardSubTitle}>
            Enter the verification code sent to your email: <br />
            {generateDottedEmail()}
          </Typography>
          <Card raised={true} sx={styles.card}>
            <Formik
              initialValues={{ emailId: "", otp: "" }}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {(props) => (
                <Form>
                  <Stack spacing={4}>
                    <Box>
                      <Field
                        component={FormikTextField}
                        value={props.values.otp || ""}
                        variant="outlined"
                        label="Verification Code"
                        fullWidth
                        name="otp"
                        InputProps={{
                          autoComplete: "off",
                        }}
                        readOnly
                      />
                      {isLoginCompleted && (
                        <CircularProgress
                          color="primary"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "45%",
                          }}
                        />
                      )}
                      <br />
                      <Stack>
                        <Button
                          primary
                          variant="contained"
                          fullWidth={true}
                          type="submit"
                          wrapperSx={{ margin: 0 }}
                          sx={styles.continueButton}
                        >
                          <Typography sx={styles.btnTextStyle}>
                            Continue
                          </Typography>
                        </Button>
                      </Stack>
                      <Box>
                        <br />
                        <Stack alignItems="center" justifyContent="center">
                          <Box style={{ display: "flex", marginBottom: "8px" }}>
                            <Typography sx={styles.receiveTextStyle}>
                              Didn’t receive a code?
                            </Typography>
                            {resendOTPCounter === 0 ? (
                              <Link
                                sx={{
                                  cursor: "pointer",
                                  width: "auto",
                                  paddingLeft: "5px",
                                  textDecoration: "none",
                                }}
                                underline="hover"
                                onClick={async () => {
                                  await getOtpFromAPI();
                                }}
                              >
                                <Typography sx={styles.decorationNone}>
                                  Resend
                                </Typography>
                              </Link>
                            ) : (
                              <Typography sx={styles.resendTextStyle}>
                                Resend in {resendOTPCounter}s
                              </Typography>
                            )}
                          </Box>
                          <br />
                          <Box style={{ display: "flex" }}>
                            <Typography style={styles.agreeTextStyle}>
                              By using the Prezerve you agree to the
                            </Typography>
                            <Link
                              sx={{
                                cursor: "pointer",
                                width: "auto",
                                paddingLeft: "3px",
                                textDecoration: "none",
                              }}
                              underline="hover"
                              onClick={handletermsOfUse}
                            >
                              <Typography sx={styles.termsOfUser}>
                                User Agreement.
                              </Typography>
                            </Link>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Card>
        </Box>
      </Stack>
    </>
  );
}
export default OTP;
