import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MuiDataGrid from "./TableDriver";

// table column defenition
const commonColumnOptions = {
  resizable: false,
  aggregable: false,
  gropable: false,
  hideable: false,
  pinnable: false,
};

const columns = [
  {
    ...commonColumnOptions,
    field: "response",
    flex: 1,
    width: 400,
    minWidth: 350,
    maxWidth: 400,
    renderHeader: () => <ColumnHeader title="Response" />,
    renderCell: (params) => <RowCell value={params.value} />,
  },
  {
    ...commonColumnOptions,
    field: "logs",
    flex: 1,
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    renderHeader: () => <ColumnHeader title="Logs" />,
    renderCell: (params) => <RowCell value={params.value} />,
  },
];

const dailyLifeImpactEnums = {
  1: "Makes me want to avoid being social",
  2: "Interfered with intimacy with my partner",
  3: "Made me feel depressed or anxious",
  4: "Interfered with work/family responsibilities",
  5: "Interfered with routine physical activities",
  6: "I started avoiding certain foods/drinks",
  7: "Other",
};

const getDailyLifeImpact = (data) => {
  let dataObject;
  Object.keys(dailyLifeImpactEnums).map((item, index) => {
    if (item == data.dailyLifeImpact) {
      dataObject = {
        id: index,
        response: Object.values(dailyLifeImpactEnums)[index],
        logs: data.count,
      };
    }
  });
  return dataObject;
};

const DailyLifeImpactTable = (props) => {
  const [dailyLifeImpactData, setDailyLifeImpact] = useState([]);

  const fetchDailyLifeImpactSection = () => {
    let dailyImpactArray = [];
    props.dailyLifeImpactList &&
      props.dailyLifeImpactList.map(async (item) => {
        dailyImpactArray.push(getDailyLifeImpact(item));
      });
    dailyImpactArray.sort(function (a, b) {
      return b.logs - a.logs;
    });
    setDailyLifeImpact(dailyImpactArray);
  };

  useEffect(() => {
    fetchDailyLifeImpactSection();
  }, []);

  return (
    <MuiDataGrid
      rows={dailyLifeImpactData}
      columns={columns}
      hidePagination={true}
      sx={{
        background: "white",
        padding: "8px",
        borderRadius: "16px",
        "&, [class^=MuiDataGrid]": { border: "none" },
        "div.MuiDataGrid-topContainer": {
          "&::after": {
            display: "none",
          },
        },
        "& .MuiDataGrid-filler > *": {
          border: "none",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "#181C210A",
        },
        boxShadow:
          "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
      }}
    />
  );
};

function ColumnHeader({ title }) {
  return (
    <Typography
      sx={{
        display: "inline",
        lineHeight: "24px",
        fontSize: "14px",
        fontWeight: "500",
        width: "100%",
      }}
    >
      {title}
    </Typography>
  );
}

function RowCell({ value }) {
  return (
    <Box sx={{ display: "inline-block", padding: "16px 6px" }}>{value}</Box>
  );
}

export default DailyLifeImpactTable;
