import React from "react";

const MoreIcon = ({ fill = "#181C218F" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 4.5C5.04688 4.5 4.5 5.08594 4.5 5.75V18.25C4.5 18.9531 5.04688 19.5 5.75 19.5H13.875V15.75C13.875 14.7344 14.6953 13.875 15.75 13.875H19.5V5.75C19.5 5.08594 18.9141 4.5 18.25 4.5H5.75ZM15.125 18.9922L18.9531 15.125H15.75C15.3984 15.125 15.125 15.4375 15.125 15.75V18.9922ZM3.25 5.75C3.25 4.38281 4.34375 3.25 5.75 3.25H18.25C19.6172 3.25 20.75 4.38281 20.75 5.75V14.1094C20.75 14.7734 20.4766 15.3984 20.0078 15.8672L15.8281 20.0469C15.3594 20.5156 14.7344 20.75 14.0703 20.75H5.75C4.34375 20.75 3.25 19.6562 3.25 18.25V5.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default MoreIcon;
