import React from "react";

const AlertIcon = ({ fill = "#181C218F" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 2.625C11.375 2.3125 11.6484 2 12 2C12.3125 2 12.625 2.3125 12.625 2.625V3.28906C15.75 3.60156 18.25 6.25781 18.25 9.5V10.6719C18.25 12.3516 18.9141 13.9922 20.125 15.2031L20.2422 15.3203C20.5547 15.6328 20.75 16.1016 20.75 16.5312C20.75 17.5078 19.9688 18.2891 18.9922 18.2891H4.96875C3.99219 18.25 3.25 17.5078 3.25 16.5312C3.25 16.0625 3.40625 15.6328 3.75781 15.3203L3.83594 15.2031C5.04688 13.9922 5.75 12.3516 5.75 10.6719V9.5C5.75 6.25781 8.21094 3.60156 11.375 3.28906V2.625ZM12 4.5C9.22656 4.5 7 6.76562 7 9.5V10.6719C7 12.7031 6.17969 14.6562 4.73438 16.1016L4.61719 16.1797C4.53906 16.2969 4.5 16.4141 4.5 16.5312C4.5 16.8047 4.69531 17 4.96875 17H18.9922C19.2656 17 19.5 16.8047 19.5 16.5312C19.5 16.4141 19.4219 16.2969 19.3438 16.1797L19.2266 16.1016C17.7812 14.6562 16.9609 12.7031 16.9609 10.6328V9.5C16.9609 6.76562 14.7344 4.5 11.9609 4.5H12ZM10.7891 19.9297C10.9844 20.4375 11.4531 20.75 12 20.75C12.5078 20.75 12.9766 20.4375 13.1719 19.9297C13.2891 19.6172 13.6406 19.4219 13.9531 19.5391C14.2656 19.6562 14.4609 20.0078 14.3438 20.3594C13.9922 21.3359 13.0547 22 12 22C10.9062 22 9.96875 21.3359 9.61719 20.3594C9.5 20.0078 9.69531 19.6562 10.0078 19.5391C10.3203 19.4219 10.6719 19.6172 10.7891 19.9297ZM6.64844 2.85938C6.88281 3.13281 6.84375 3.52344 6.57031 3.75781C4.89062 5.125 3.875 7.19531 3.875 9.5C3.875 9.85156 3.5625 10.125 3.25 10.125C2.89844 10.125 2.625 9.85156 2.625 9.5C2.625 6.80469 3.83594 4.38281 5.75 2.78125C6.02344 2.54688 6.41406 2.58594 6.64844 2.85938ZM17.5078 3.75781C17.2734 3.52344 17.2344 3.13281 17.4297 2.85938C17.6641 2.58594 18.0547 2.58594 18.3281 2.78125C20.2422 4.38281 21.4922 6.80469 21.4922 9.5C21.4922 9.85156 21.1797 10.125 20.8672 10.125C20.5156 10.125 20.2422 9.85156 20.2422 9.5C20.2422 7.19531 19.1875 5.125 17.5078 3.75781Z"
        fill={fill}
      />
    </svg>
  );
};

export default AlertIcon;
