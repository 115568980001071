import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import lotImage from "../../assets/images/lot.svg";
import snImage from "../../assets/images/sn.svg";
import manufacturerImage from "../../assets/images/manufacturer.svg";
import warningImage from "../../assets/images/warning.svg";
import WebLogo from "../../assets/images/weblogo.png";
import { Helmet } from "react-helmet";

const useStyles = () => {
  return {
    subTitleText: {
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "24.7px",
      letterSpacing: "0.25px",
      color: "#171C22",
    },
    normalText: {
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#171C22B2",
      whiteSpace: "pre-line",
    },
  };
};

function AboutPage() {
  const styles = useStyles();
  const userId = localStorage.getItem("userId");
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <Box
        sx={{
          margin: "0px auto",
          maxWidth: "562px",
          width: "100%",
        }}
      >
        <Stack
          alignItems={"center"}
          sx={{
            paddingBottom: "32px",
          }}
        >
          <img src={WebLogo} alt="Prezerve logo" height={"66px"} />
        </Stack>
        <Stack spacing={"24px"}>
          <Details
            image={lotImage}
            alt="LOT"
            title={"Version"}
            value={process.env.REACT_APP_VERSION ?? "XXXX"}
          />
          <Details
            image={snImage}
            alt={"serial number"}
            title={"Serial Number"}
            value={
              process.env.REACT_APP_VERSION && userId
                ? `${process.env.REACT_APP_VERSION}.${userId}`
                : "XXXX"
            }
          />
          <Details
            image={manufacturerImage}
            alt={"manufacturer information"}
            title={"Manufacturer"}
            value={`Manufactured on behalf of, Incyte\n1815 Augustine Cut Off, Wilmington, DE 19803`}
          />
          <Details
            image={warningImage}
            alt={"caution message"}
            title={"Caution message"}
            value={`Remember that a Healthcare Professional is the single best source of medical advice regarding your health. 
            Please consult a Healthcare Professional if you have any questions. 
            By clicking third-party links, you are going to an external, entirely independent third-party website. 
            Incyte provides these links as a service to its mobile application visitors and users and takes no 
            responsibility for any information presented on such sites or for your use of or access to such sites.`}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Typography sx={styles.subTitleText}>Intended use</Typography>
            <Typography sx={styles.normalText}>
              This tracking tool is intended for use as a resource for patients
              who are recovering from a bone marrow transplant. This application
              is to be used primarily for symptoms tracking purposes and is not
              intended to provide medical, diagnosis, or treatment advice.
              Information provided in this tool, including the outputs from its
              interactive features, should not be considered an alternative to
              medical advice from your Healthcare Professional Team. This mobile
              application is for US residents 18 years of age or older. The
              information on this mobile application is not a substitute for a
              visit or consultation with a Healthcare Professional.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            <Typography sx={styles.subTitleText}>
              Supporting operating systems
            </Typography>
            <Typography sx={styles.normalText}>
              Prezerve App supports the following operating systems.
              <Box component={"ul"} sx={{ margin: 0 }}>
                <li>Android phones OS version from 10.x and above.</li>
                <li>iPhone OS version 16.x and above.</li>
              </Box>
              When a new operating system update becomes available, a
              compatibility test will be conducted prior to release.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

function Details({ image, alt, title, value }) {
  const styles = useStyles();
  return (
    <Stack direction={"row"} spacing={"16px"}>
      <Box sx={{ alignSelf: "baseline" }}>
        <img src={image} alt={alt} />
      </Box>
      <Stack spacing={"2px"}>
        <Typography sx={styles.subTitleText}>{title}</Typography>
        <Typography sx={styles.normalText}>{value}</Typography>
      </Stack>
    </Stack>
  );
}

export default AboutPage;
