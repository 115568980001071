import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function MuiDataGrid({
  rows,
  columns,
  sx,
  onRowClick,
  paginationModel,
  onPaginationModelChange,
  hidePagination = true,
  ...additionalTableOptions
}) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnMenu={true}
      disableColumnSelector={true}
      showCellVerticalBorder={false}
      showColumnVerticalBorders={false}
      disableRowSelectionOnClick={true}
      onRowClick={onRowClick}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      getRowHeight={() => "auto"}
      sx={{
        border: "none",
        "& .MuiTablePagination-displayedRows": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "19.92px",
          letterSpacing: "0.4px",
          color: "rgba(23, 28, 34, 1)",
        },
        "& .MuiTablePagination-selectLabel": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "19.92px",
          letterSpacing: "0.4px",
          color: "rgba(23, 28, 34, 0.7)",
        },
        "& .MuiTablePagination-select": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "19.92px",
          letterSpacing: "0.4px",
          color: "rgba(23, 28, 34, 1)",
        },
        ...sx,
      }}
      {...additionalTableOptions}
      hideFooterPagination={hidePagination}
    />
  );
}

export default MuiDataGrid;
