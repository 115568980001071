import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import RenderAppBasedOnInternet from "./RenderAppBasedOnInternet";
import { Amplify, Analytics } from "aws-amplify";
import {
  awsPinPointConfig,
  sessionConfig,
  pageOptions,
  initialisePinPointConfig,
} from "./pinpointConfig";
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById("root"));

let getConfig = async () => {
  let result = await axios.get("/api/resourceService/getDefaultConfig");
  initialisePinPointConfig(
    result.data.pinPointDetails.idpId,
    result.data.pinPointDetails.region,
    result.data.pinPointDetails.appId
  );
  Amplify.configure(awsPinPointConfig()["Auth"]);
  Amplify.configure(awsPinPointConfig()["Analytics"]);
  Analytics.autoTrack("session", sessionConfig);
  Analytics.autoTrack("pageView", pageOptions);
  return result.data;
};

getConfig().then(
  root.render(
    <React.StrictMode>
      <CssBaseline />
      <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
        <RenderAppBasedOnInternet />
      </BrowserRouter>
    </React.StrictMode>
  )
);
