const LoginPageStyle = (theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
    },
    otpContainer: {
      marginTop: "25%",
      height: "100%",
      width: "100%",
    },
    card: {
      padding: "24px",
      borderRadius: "10px",
      boxShadow:
        "0px 0px 0px 0px rgba(0,0,0,0.02), 0px 0px 0px 0px rgba(0,0,0,0.02), 0px 0px 0px 0px rgba(0,0,0,0.02), 0px 0px 0px 0px rgba(0,0,0,0.02), 1px 3px 14px 2px rgba(0,0,0,0.02)",
      width: { xs: "100%", sm: "500px" },
    },
    cardTitle: {
      fontSize: "34px",
      color: "#171C22",
      fontWeight: 500,
      lineHeight: "40.8px",
      letterSpacing: "-0.5px",
      textAlign: "center",
    },
    cardSubTitle: {
      fontSize: "16px",
      color: "#2F363CDE",
      lineHeight: "24px",
      fontWeight: 400,
      letterSpacing: ".15px",
      textAlign: "center",
    },
    inputButton: { cursor: "pointer" },
    sendOtpText: {
      color: "#2F363CC4",
      fontSize: "14px",
      letterSpacing: "0.5px",
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: "12px",
    },
    resentLink: {
      textDecoration: "none",
      paddingLeft: "15px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.4",
    },
    continueButton: {
      minWidth: "242px !important",
      borderRadius: "25px",
      padding: "10px 25px",
      textTransform: "none",
      backgroundColor: "#00639C",
    },
    btnTextStyle: {
      fontSize: "15px",
      letterSpacing: "0.46px",
      lineHeight: "26px",
      fontWeight: 500,
    },
    receiveTextStyle: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20.02px",
      letterSpacing: "0.17",
      color: "rgba(23, 28, 34, 1)",
    },
    resendTextStyle: {
      paddingLeft: "5px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "19.92px",
      letterSpacing: "0.4",
      color: "rgba(23, 28, 34, 0.38)",
    },
    agreeTextStyle: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19.92px",
      letterSpacing: "0.4",
      color: "rgba(23, 28, 34, 0.7)",
    },
    termsOfUser: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19.92px",
      letterSpacing: "0.4",
      color: "#00639C",
    },
    decorationNone: {
      paddingLeft: "5px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "19.92px",
      letterSpacing: "0.4",
      color: "#00639C",
    },
  };
};

export { LoginPageStyle };
