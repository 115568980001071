import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Link,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import LogOutDialog from "./LogOutDialog";
import ProfileIcon from "../assets/images/profile.svg";
import AboutIcon from "../assets/images/about.svg";
import UserAgreementIcon from "../assets/images/user-agreement.svg";
import LogoutIcon from "../assets/images/logout.svg";
import { useHistory } from "react-router-dom";
import WebLogo from "../assets/images/weblogo.png";

function NavBar() {
  const [siteCode] = React.useState(() => localStorage.getItem("siteCode"));
  const [userName] = React.useState(() => localStorage.getItem("userName"));
  const { logout } = React.useContext(AuthContext);
  const [showLogoutDialog, setShowLogoutDialog] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      {showLogoutDialog && (
        <LogOutDialog
          setShowLogoutDialog={setShowLogoutDialog}
          logout={logout}
        />
      )}
      <AppBar
        position="fixed"
        component="nav"
        sx={{
          backgroundColor: "inherit",
          color: "black",
          boxShadow: "none",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            padding: "20px 24px",
            justifyContent: "space-between",
          }}
        >
          <Link href="/hcp">
            <img src={WebLogo} alt="prezerve logo" style={{ height: "32px" }} />
          </Link>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Typography
              sx={{
                color: "#171C22B2",
                letterSpacing: "0.15px",
                lineHeight: "24px",
              }}
            >
              Site ID:
            </Typography>
            {siteCode && (
              <Typography
                sx={{
                  color: "#1D1A22",
                  letterSpacing: "0.15px",
                  lineHeight: "24px",
                }}
              >
                {siteCode}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography
              sx={{
                letterSpacing: "0.15px",
                lineHeight: "24px",
                color: "#171C22B2",
              }}
            >
              {userName ?? ""}
            </Typography>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: "initial",
                width: "40px",
                padding: "10px",
              }}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
            >
              <img src={ProfileIcon} alt="profile icon" width={"24px"} />
            </Button>
            <Menu
              sx={{ mt: "40px", ml: "15px" }}
              slotProps={{
                paper: {
                  sx: {
                    width: "220px",
                  },
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <UserActionItem
                icon={AboutIcon}
                text="About"
                onClick={() => {
                  history.push("/hcp/about");
                  handleCloseUserMenu();
                }}
              />
              <UserActionItem
                icon={UserAgreementIcon}
                text="User Agreement"
                onClick={() => {
                  window.open(
                    "/api/V1/resourceService/termsAndCondition/3",
                    "_blank"
                  );
                  handleCloseUserMenu();
                }}
              />
              <UserActionItem
                icon={LogoutIcon}
                text="Logout"
                onClick={() => {
                  setShowLogoutDialog(true);
                  handleCloseUserMenu();
                }}
              />
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

function UserActionItem({ icon, text, onClick }) {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        width: "100%",
        color: "#171C22",
      }}
    >
      <Box sx={{ height: "24px", width: "24px" }}>
        <img src={icon} alt="about icon" height={"100%"} />
      </Box>
      <Typography
        sx={{
          lineHeight: "24px",
          letterSpacing: "0.15px",
        }}
      >
        {text}
      </Typography>
    </MenuItem>
  );
}

export default NavBar;
