import React from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import BackBtn from "../../components/BackBtn";
import PatientViewTabs from "./PatientViewTabs";
import {
  PATIENT_DURATIONS,
  PATIENT_TABS,
  getName,
  calculateDuration,
} from "../../CommonFunctions";
import { AuthContext } from "../../context/AuthContext";

const PatientView = () => {
  const params = useParams();
  const history = useHistory();
  const { makeAuthenticatedApiCall } = React.useContext(AuthContext);
  const [patientDetail, setPatientDetail] = React.useState(null);
  const [symptomData, setSymptomData] = React.useState(null);
  const [symptomLoading, setSymptomLoading] = React.useState(true);

  const fetchData = React.useCallback(async (type, options) => {
    const { startDate, endDate } = calculateDuration(options.duration);
    let apiCalls;
    if (type === "full") {
      apiCalls = await Promise.allSettled([
        makeAuthenticatedApiCall(
          "GET",
          `/api/V1/providerService/getPatientDetails/${options.patientId}`
        ).then((r) => ({ name: "patient-details", result: r })),
        makeAuthenticatedApiCall(
          "GET",
          `/api/V1/providerService/getSavedSymptoms/${options.patientId}?startDate=${startDate}&endDate=${endDate}`
        ).then((r) => ({ name: "saved-symptoms", result: r })),
      ]);
    } else if (type === "duration-change") {
      apiCalls = await Promise.allSettled([
        makeAuthenticatedApiCall(
          "GET",
          `/api/V1/providerService/getSavedSymptoms/${options.patientId}?startDate=${startDate}&endDate=${endDate}`
        ).then((r) => ({ name: "saved-symptoms", result: r })),
      ]);
    }
    if (
      apiCalls.every(
        (apiCall) =>
          apiCall.status === "fulfilled" && apiCall.value.result.status === 200
      )
    ) {
      const patientDetailsFromAPI = apiCalls.find(
        (apiCall) => apiCall.value.name === "patient-details"
      );
      if (patientDetailsFromAPI?.status === "fulfilled") {
        setPatientDetail(patientDetailsFromAPI.value.result.data);
      }
      const savedSymptomsFromAPI = apiCalls.find(
        (apiCall) => apiCall.value.name === "saved-symptoms"
      );
      if (savedSymptomsFromAPI?.status === "fulfilled") {
        setSymptomLoading(false);
        setSymptomData(savedSymptomsFromAPI.value.result.data);
      }
    } else {
      console.error("[Error fetching]", apiCalls);
      history.replace("/hcp");
    }
  }, []);

  React.useEffect(() => {
    // invalid tab or duration correction
    const isTabOk =
      Object.values(PATIENT_TABS).findIndex(
        (tabObj) => tabObj.val === params.tab
      ) !== -1;
    const isDurationOk =
      Object.values(PATIENT_DURATIONS).findIndex(
        (durObj) => durObj.val === params.duration
      ) !== -1;
    if (!(isTabOk && isDurationOk)) {
      history.replace("/hcp");
    }
    // fetch patientDetails and savedSymptoms
    fetchData("full", {
      duration: params.duration,
      tab: params.tab,
      patientId: params.patientId,
    });
  }, []);

  const navigate = ({ tab, duration }) => {
    const path = generatePath("/hcp/patient/:id/:tab/:duration", {
      id: params.patientId,
      tab: tab ?? params.tab,
      duration: duration ?? params.duration,
    });
    history.replace(path.toString());
  };

  const changeTab = (newTabIndex) => {
    let tabValue;
    Object.values(PATIENT_TABS).forEach((tabObj) => {
      if (tabObj.tabIndex === newTabIndex) {
        tabValue = tabObj.val;
      }
    });
    if (!tabValue) {
      tabValue = PATIENT_TABS.SYMPTOM.val;
    }
    console.log("[tab]", params.tab, "->", tabValue);
    navigate({ tab: tabValue });
  };

  const changeDuration = async (newDuration) => {
    console.log("[duration]", params.duration, "->", newDuration);
    navigate({ duration: newDuration });
    setSymptomLoading(true);
    fetchData("duration-change", {
      duration: newDuration,
      tab: params.tab,
      patientId: params.patientId,
    });
  };
  return (
    patientDetail &&
    symptomData && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Box
          component={"header"}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <BackBtn />
          <Typography
            variant="h1"
            sx={{
              fontSize: "34px",
              letterSpacing: "-0.5px",
              lineHeight: "40.8px",
              fontWeight: "500",
            }}
          >
            {getName(patientDetail.firstName, patientDetail.lastName)}
          </Typography>
          <Box
            about="patient details"
            sx={{
              display: "flex",
              gap: "32px",
            }}
          >
            <PatientDetail
              title="Date of Birth:"
              value={dayjs(patientDetail.dob).format("MMM DD, YYYY")}
            />
            <PatientDetail
              title="Last app usage:"
              value={dayjs(patientDetail.lastLogin).format("MMM DD, YYYY")}
            />
          </Box>
        </Box>
        <PatientViewTabs
          tab={params.tab}
          duration={params.duration}
          changeTab={changeTab}
          changeDuration={changeDuration}
          loading={symptomLoading}
          data={symptomData}
        />
      </Box>
    )
  );
};

function PatientDetail({ title, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Typography
        sx={{
          lineHeight: "24px",
          color: "#171C22B2",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          lineHeight: "24px",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default PatientView;
