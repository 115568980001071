import dayjs from "./day";

export const getName = (firstName, lastName) => {
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || "";
};

export const PATIENT_TABS = {
  SYMPTOM: { val: "symptom", tabIndex: 0 },
  ALERT: { val: "alert", tabIndex: 1 },
  MORE: { val: "more", tabIndex: 2 },
};

export const PATIENT_DURATIONS = {
  ONE_MONTH: {
    val: "1m",
    duration: dayjs.duration(1, "M"), // 30d
    interval: dayjs.duration(7, "d"),
  },
  THREE_MONTHS: {
    val: "3m",
    duration: dayjs.duration(3, "M"), //90d
    interval: dayjs.duration(15, "d"),
  },
  SIX_MONTHS: {
    val: "6m",
    duration: dayjs.duration(6, "M"), // 180d
    interval: dayjs.duration(30, "d"),
  },
  ALL: {
    val: "all",
    duration: dayjs.duration(2, "y"), // 730d
    interval: dayjs.duration(120, "d"),
  },
};

export const calculateDuration = (duration) => {
  const durObj = Object.values(PATIENT_DURATIONS).find(
    (d) => d.val === duration
  );
  const endDate = dayjs().set("hour", 12).set("minute", 0).set("second", 0);
  const startDate = endDate.subtract(durObj.duration, "d");
  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
    raw: {
      durObj,
      startDate,
      endDate,
    },
  };
};

export const calculateInterval = (duration) => {
  const {
    raw: { durObj, startDate, endDate },
  } = calculateDuration(duration);
  let currentEnd = endDate;
  const intervals = [];
  while (currentEnd.isAfter(startDate)) {
    let currentStart = currentEnd.subtract(durObj.interval, "d");
    // correction for leap month feb
    if (currentStart.hour() !== 12) {
      currentStart = currentStart.add(1, "d").set("hour", 12);
    }
    intervals.push({ start: currentStart.add(1, "d"), end: currentEnd });
    currentEnd = currentStart;
  }
  intervals.reverse();
  return { startDate, endDate, intervals };
};

export const symptomBothersomeDisplayScale = [
  "Not at all",
  "Slightly",
  "Moderately",
  "Quite a bit",
  "Extremely",
];
