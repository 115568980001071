import React from "react";
import { Typography, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
const BackBtn = () => {
  return (
    <Box
      component={"a"}
      href="javascript:history.back()"
      sx={{
        padding: "8px 11px",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "#00639C",
      }}
    >
      <ChevronLeftIcon />
      <Typography
        component={"span"}
        sx={{
          fontSize: "15px",
          fontWeight: "500",
          letterSpacing: "0.46px",
          lineHeight: "26px",
        }}
      >
        Back
      </Typography>
    </Box>
  );
};

export default BackBtn;
