import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const ScreenSizeRestrict = () => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => 0}
      sx={{
        "& .MuiDialog-container": {
          backdropFilter: "blur(10px)",
        },
      }}
    >
      <DialogTitle>Warning message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This Website is only available for desktop. Please use a bigger screen
          device
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ScreenSizeRestrict;
