import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AuthContext } from "../context/AuthContext";
import MuiDataGrid from "./TableDriver";
import NoDataFoundComponent from "./NoDataComponent";
import CircleExclamationIcon from "../assets/icons/CircleExclamationIcon";
import SeenIcons from "../assets/icons/SeenIcons";

// table column defenition
const commonColumnOptions = {
  resizable: false,
  aggregable: false,
  gropable: false,
  hideable: false,
  pinnable: false,
};
const OTP_COUNTER_TIMEOUT_IN_SECONDS = 5;

const severityEnums = {
  1: "Not at all",
  2: "Slightly",
  3: "Moderately",
  4: "Quite a bit",
  5: "Extremely",
};

const getSeverityEnums = (data) => {
  let dataObject;
  Object.keys(severityEnums).map((item, index) => {
    if (item == data) {
      dataObject = Object.values(severityEnums)[index];
    }
  });
  return dataObject;
};

const NotificationTable = () => {
  const params = useParams();
  const resendOTPCouterIntervalRef = useRef();
  const [notifications, setNotifications] = useState([]);
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const [autoUpdateCounter, setAutoUpdateCounter] = useState(
    OTP_COUNTER_TIMEOUT_IN_SECONDS
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      ...commonColumnOptions,
      field: "symptomDate",
      flex: 1,
      width: 100,
      minWidth: 150,
      maxWidth: 150,
      renderHeader: () => <ColumnHeaders title="Date" />,
      renderCell: (params) => (
        <RowCells value={dayjs(params.value).format("MMM D, YYYY")} />
      ),
    },
    {
      ...commonColumnOptions,
      field: "symptomName",
      flex: 1,
      width: 800,
      minWidth: 800,
      maxWidth: 850,
      renderHeader: () => <ColumnHeaders title="Symptom" />,
      renderCell: (params) => <RowCells value={params.value} />,
    },
    {
      ...commonColumnOptions,
      field: "severity",
      flex: 1,
      width: 150,
      minWidth: 200,
      maxWidth: 200,
      renderHeader: () => <ColumnHeaders title="How Bothersome" />,
      renderCell: (params) => (
        <RowSeverity value={getSeverityEnums(params.value)} />
      ),
    },
    {
      ...commonColumnOptions,
      sortable: false,
      field: "readStatus",
      flex: 0.3,
      width: 103,
      minWidth: 103,
      maxWidth: 103,
      renderHeader: () => <ColumnHeaders title="Status" />,
      renderCell: (params) => {
        return params.value === 0 ? <UnReadStatus /> : <SeenStatus />;
      },
    },
  ];

  const fetchPatients = useCallback(async () => {
    try {
      const response = await makeAuthenticatedApiCall(
        "GET",
        `/api/V1/providerService/getSymptomNotifications/${params.patientId}`
      );
      if (response.status === 200) {
        setNotifications(response.data);
        return response.data;
      } else {
        console.log("error fetching patients");
        return;
      }
    } catch (err) {
      console.error("getpatientlist error");
    }
  }, [setNotifications, makeAuthenticatedApiCall]);

  const handleUpdateNotification = async (notificationIds) => {
    await makeAuthenticatedApiCall(
      "POST",
      `/api/V1/providerService/updateNotifications/${params.patientId}`,
      {
        notificationIds,
      }
    );
  };
  const notificationCounter = (startIndex, endIndex, notifications) => {
    if (resendOTPCouterIntervalRef.current) {
      clearInterval(resendOTPCouterIntervalRef.current);
    }
    setAutoUpdateCounter(OTP_COUNTER_TIMEOUT_IN_SECONDS);
    resendOTPCouterIntervalRef.current = setInterval(() => {
      setAutoUpdateCounter((prevCounter) => {
        if (prevCounter === 0) {
          let currentPageData = notifications.slice(startIndex, endIndex);
          let notificationIds = [];
          currentPageData &&
            currentPageData.map((data) => {
              if (data.readStatus === 0) {
                notificationIds.push(data.id);
              }
            });

          if (notificationIds.length > 0) {
            let newNotifications = notifications.map((item) => {
              notificationIds.find((element) => {
                if (element === item.id) {
                  return (item.readStatus = 1);
                }
              });
              return item;
            });
            setNotifications(newNotifications);
            handleUpdateNotification(notificationIds);
          }
          clearInterval(resendOTPCouterIntervalRef.current);
          resendOTPCouterIntervalRef.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };
  useEffect(() => {
    fetchPatients().then((notifications) => {
      let startIndex = paginationModel.page * paginationModel.pageSize;
      let endIndex = startIndex + paginationModel.pageSize;
      if (notifications.length > 0)
        notificationCounter(startIndex, endIndex, notifications);
    });
  }, []);

  const handlePaginationClicked = (pageDetails) => {
    let startIndex = pageDetails.page * pageDetails.pageSize;
    let endIndex = startIndex + pageDetails.pageSize;
    setPaginationModel(pageDetails);
    notificationCounter(startIndex, endIndex, notifications);
  };
  return (
    <>
      {notifications && notifications.length > 0 ? (
        <MuiDataGrid
          rows={notifications}
          columns={columns}
          getRowHeight={() => "auto"}
          hidePagination={false}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationClicked}
          sx={{
            background: "white",
            padding: "8px",
            borderRadius: "16px",
            "&, [class^=MuiDataGrid]": { border: "none" },
            "div.MuiDataGrid-topContainer": {
              "&::after": {
                display: "none",
              },
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#181C210A",
            },
            boxShadow:
              "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
          }}
          pageSizeOptions={[10, 20, 30]}
        />
      ) : (
        <NoDataFoundComponent
          data={{ width: "1390px", height: "300px", paddingTop: "10%" }}
        />
      )}
    </>
  );
};

function ColumnHeaders({ title }) {
  return (
    <Typography
      sx={{
        display: "inline",
        lineHeight: "24px",
        fontSize: "14px",
        fontWeight: "500",
        width: "100%",
      }}
    >
      {title}
    </Typography>
  );
}

function RowCells({ value }) {
  return (
    <Box sx={{ display: "inline-block", padding: "16px 6px" }}>{value}</Box>
  );
}

function RowSeverity({ value }) {
  return (
    <Box
      sx={{
        marginTop: "9px",
        height: "32px",
        background: "rgba(24, 28, 33, 0.08)",
        display: "inline-block",
        borderRadius: "50px",
        padding: "7px 20px 9px 20px",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "0.16px",
        color: "rgba(23, 28, 34, 1)",
      }}
    >
      {value}
    </Box>
  );
}

function UnReadStatus() {
  return (
    <Box
      sx={{
        marginTop: "9px",
        display: "flex",
        height: "32px",
        background: "rgba(255, 218, 214, 1)",
        borderRadius: "50px",
        padding: "7px 3px 3px 10px",
      }}
    >
      <Box>
        <CircleExclamationIcon />
      </Box>
      <Box
        sx={{
          marginLeft: "8px",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "18px",
          letterSpacing: "0.16px",
          color: "rgba(65, 0, 2, 1)",
        }}
      >
        New
      </Box>
    </Box>
  );
}

function SeenStatus() {
  return (
    <Box
      sx={{
        marginTop: "9px",
        display: "flex",
        height: "32px",
        background: "rgba(213, 228, 247, 1)",
        borderRadius: "50px",
        padding: "7px 3px 3px 10px",
      }}
    >
      <Box>
        <SeenIcons />
      </Box>
      <Box
        sx={{
          marginLeft: "8px",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "18px",
          letterSpacing: "0.16px",
          color: "rgba(65, 0, 2, 1)",
        }}
      >
        Seen
      </Box>
    </Box>
  );
}

export default NotificationTable;
