import { createTheme } from "@mui/material";
const theme = createTheme({
  textColour: {
    primaryColor: "#00639C",
  },
  btnColour: {
    btnBackgroundColour: "#00639C",
    btnTextColour: "#fff",
    btnSecondaryBackground: "rgba(105, 87, 178, 0.08)",
  },
  custom: {
    buttonDisabledBg: "rgba(243, 244, 245, 1)",
    activeNavLink: "#00639C",
  },
  palette: {
    accent3: {
      main: "#382D82",
      background: "rgba(105, 87, 178, 0.08)",
    },
    text: {
      primary: "#171C22",
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: `Ubuntu, system-ui`,
      letterSpacing: "0.17px",
      lineHeight: "20.02px",
    },
  },
});
export default theme;
