import React from "react";
import { Typography, Box } from "@mui/material";
import PatientTable from "../../components/PatientTable";

function PatientDash() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: "34px",
            fontWeight: "500",
            letterSpacing: "-0.5px",
            lineHeight: "40.8px",
          }}
        >
          Patients
        </Typography>
      </Box>
      <Box>
        <PatientTable />
      </Box>
    </Box>
  );
}

export default PatientDash;
