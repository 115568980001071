import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import MuiDataGrid from "./TableDriver";
import dayjs from "dayjs";

// table column defenition
const commonColumnOptions = {
  resizable: false,
  aggregable: false,
  gropable: false,
  hideable: false,
  pinnable: false,
};
const getObservationDate = (date) => {
  return dayjs(date).format("MMM D, YYYY");
};
const NotApplicableUI = () => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        lineHeight: "20.02px",
        letterSpacing: "0.17px",
        color: "#171C22",
        fontSize: "14px",
        display: "inline-block",
        padding: "16px 6px",
      }}
    >
      N/A
    </Typography>
  );
};

const columns = [
  {
    ...commonColumnOptions,
    field: "observationDate",
    flex: 1,
    width: 110,
    minWidth: 100,
    maxWidth: 120,
    renderHeader: () => <ColumnHeaderUI title="Date" />,
    renderCell: (params) => (
      <RowCell value={getObservationDate(params.value)} />
    ),
  },
  {
    ...commonColumnOptions,
    field: "observationText",
    flex: 1,
    width: 500,
    minWidth: 400,
    maxWidth: 500,
    renderHeader: () => <ColumnHeaderUI title="Note" />,
    renderCell: (params) => <RowCell value={params.value} />,
  },
  {
    ...commonColumnOptions,
    field: "relatedSymptoms",
    flex: 1,
    width: 400,
    minWidth: 350,
    maxWidth: 400,
    renderHeader: () => <ColumnHeaderUI title="Related Symptoms" />,
    renderCell: ({ value }) => (
      <Stack height={"100%"}>
        {Array.isArray(value) && value.length > 0 ? (
          value.map((item, i) =>
            item ? (
              <Box sx={{ display: "flex", marginTop: "15px" }} key={i}>
                <li></li>
                <Typography
                  key={i}
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20.02px",
                    letterSpacing: "0.17px",
                    color: "#171C22",
                  }}
                >
                  {item}
                </Typography>
              </Box>
            ) : (
              <NotApplicableUI key={i} />
            )
          )
        ) : (
          <NotApplicableUI />
        )}
      </Stack>
    ),
  },
];

const MoreTable = (props) => {
  return (
    <MuiDataGrid
      rows={props.moreData}
      columns={columns}
      sx={{
        background: "white",
        padding: "8px",
        borderRadius: "16px",
        "&, [class^=MuiDataGrid]": { border: "none" },
        "div.MuiDataGrid-topContainer": {
          "&::after": {
            display: "none",
          },
        },
        "& .MuiDataGrid-filler > *": {
          border: "none",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "#181C210A",
        },
        boxShadow:
          "0px 6px 38px -23px #192A554D, 0px 6px 38px -31px #192A5533, 0px 8px 15px -31px #192A554D",
      }}
      hidePagination={false}
      pageSizeOptions={[10, 20, 30]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
    />
  );
};

function ColumnHeaderUI({ title }) {
  return (
    <Typography
      sx={{
        display: "inline",
        lineHeight: "24px",
        letterSpacing: "0.17px",
        color: "#171C22",
        fontSize: "14px",
        fontWeight: "500",
        padding: "0px 6px",
      }}
    >
      {title}
    </Typography>
  );
}

function RowCell({ value }) {
  return (
    <Box
      sx={{
        display: "inline-block",
        padding: "16px 6px",
        textAlign: "justify",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20.02px",
        letterSpacing: "0.17px",
        color: "#171C22",
      }}
    >
      {value}
    </Box>
  );
}

export default MoreTable;
