import React, { useState } from "react";
import {
  Card,
  Typography,
  CircularProgress,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Button from "../../components/customcomponents/CustomButton";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Helmet } from "react-helmet";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import axios from "axios";
import { LoginPageStyle } from "../../components/customstyles/LoginPageStyle";

const useStyles = () => {
  const theme = useTheme();
  return LoginPageStyle(theme);
};

const MAX_CHARS_IN_EMAIL = 78;
function LoginPage() {
  const styles = useStyles();
  const history = useHistory();
  const [loginLoading, setLoginLoading] = useState(false);

  const yupSchema = object({
    userEmailId: string()
      .trim()
      .max(MAX_CHARS_IN_EMAIL, "Email length too long.")
      .required("This field is required.")
      .email("Please enter a valid email id."),
  });

  const handleSubmit = async (values, formHelper) => {
    setLoginLoading(true);
    // call the send otp API
    localStorage.setItem("emailId", values.userEmailId);
    try {
      const response = await axios({
        method: "post",
        url: "/api/providerAuthService/sendOtp",
        data: { email: values.userEmailId.trim() },
      });
      if (response?.status === 200 && response.data.token) {
        localStorage.setItem("otpToken", response.data.token);
        history.push("./otp");
      }
    } catch (err) {
      if (err.response?.status === 400) {
        formHelper.setFieldError(
          "userEmailId",
          "Email address is not authorized."
        );
      } else {
        // just log any other error
        console.log("[send otp error]", err);
      }
    }
    setLoginLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Stack
        sx={{
          height: "100vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack gap={"24px"}>
          <Stack gap={"8px"}>
            <Typography sx={styles.cardTitle}>Welcome</Typography>
            <Typography sx={styles.cardSubTitle}>
              Enter your email address to begin
            </Typography>
          </Stack>
          <Card raised={true} sx={styles.card}>
            <Formik
              initialValues={{ userEmailId: "", otp: "" }}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {() => (
                <Form>
                  <Stack gap={"24px"}>
                    <Box>
                      <Field
                        name="userEmailId"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        label="Email Address "
                        component={FormikTextField}
                        InputProps={{
                          style: {
                            backgroundColor: "#fff",
                            minHeight: "47px",
                            fontSize: "15px",
                          },
                        }}
                      />
                      {loginLoading && (
                        <CircularProgress
                          color="primary"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "45%",
                          }}
                        />
                      )}
                    </Box>
                    <Stack spacing={1}>
                      <Button
                        primary
                        variant="contained"
                        fullWidth={true}
                        type="submit"
                        wrapperSx={{ margin: 0 }}
                        sx={styles.continueButton}
                      >
                        <Typography sx={styles.btnTextStyle}>
                          Continue
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}
export default LoginPage;
